import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledContactForm = styled.div`
  padding-top: ${Rem(0)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(0)};
  }
`;

export const StyledContactFormSubmit = styled.div`
  position: absolute;
  bottom: 60px;
  right: 20px;
  display: flex;
  justify-content: center;

  @media (${BP.ipad}) {
    bottom: -90px;
    left: 20px;
    right: auto;
  }
`;
